import React, { Suspense, lazy, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import controllers from "../lib/api";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import mixpanel from "../lib/mixpanel";
import { Helmet } from "react-helmet";
import EnDictionary from "../locales/en/gifts.json";
import IdDictionary from "../locales/id/gifts.json";
import Expired from "../components/gifts/Expired";

// import Profile from "../components/gifts/Profile";
// import ProfileTempoorary from "../components/gifts/ProfileTempoorary";
// import Start from "../components/gifts/Start";
// import Choose from "../components/gifts/Choose";
// import Variation from "../components/gifts/Variation";
// import Exchange from "../components/gifts/Exchange";
// import Address from "../components/gifts/Address";
// import NewAddress from "../components/gifts/NewAddress";
// import Digital from "../components/gifts/Digital";
// import ThankYou from "../components/gifts/ThankYou";
// import ThankYouDigital from "../components/gifts/ThankYouDigital";
// import Delivering from "../components/gifts/Delivering";
// import ReviewDigital from "../components/gifts/ReviewDigital";

const ProfileDefault = lazy(() => import("../components/gifts/Profile/ProfileDefault"));
const ProfileEvent = lazy(() => import("../components/gifts/Profile/ProfileEvent"));
const Start = lazy(() => import("../components/gifts/Start"));
const Choose = lazy(() => import("../components/gifts/Choose"));
const Variation = lazy(() => import("../components/gifts/Variation"));
const Exchange = lazy(() => import("../components/gifts/Exchange"));
const Address = lazy(() => import("../components/gifts/Address"));
const NewAddress = lazy(() => import("../components/gifts/NewAddress"));
const Digital = lazy(() => import("../components/gifts/Digital"));
const ThankYou = lazy(() => import("../components/gifts/ThankYou"));
const ThankYouDigital = lazy(() => import("../components/gifts/ThankYouDigital"));
const Delivering = lazy(() => import("../components/gifts/Delivering"));
const ReviewDigital = lazy(() => import("../components/gifts/ReviewDigital"));

function GiftLayout() {
  let { token, lang } = useParams();

  const [firstLoading, setFirstLoading] = useState(true);
  const [firstRender, setFirstRender] = useState(false);
  const [orderRecipient, setOrderRecipient] = useState({});
  const [recipientName, setRecipientName] = useState("");
  const [productsData, setProductsData] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const [choosensData, setChoosensData] = useState([]);
  const [includedata, setIncludedata] = useState([]);
  const [needRecipientVariation, setNeedRecipientVariation] = useState(false);
  const [step, setStep] = useState("");
  const [test, setTest] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const [statusCodeZero, setStatusCodeZero] = useState(false);

  const dictionary = lang === "en" ? EnDictionary : IdDictionary;
  const digitalProvider = {
    indomaret: {
      tnc: [dictionary["tnc_indomaret_1"], dictionary["tnc_indomaret_2"]],
      how: [dictionary["how_indomaret_1"], dictionary["how_indomaret_2"], dictionary["how_indomaret_3"]],
    },
    alfamart: {
      tnc: [dictionary["tnc_alfamart_1"], dictionary["tnc_alfamart_2"], dictionary["tnc_alfamart_3"]],
      how: [dictionary["how_alfamart_1"], dictionary["how_alfamart_2"], dictionary["how_alfamart_3"]],
    },
    map: {
      tnc: [
        dictionary["tnc_map_1"],
        dictionary["tnc_map_2"],
        dictionary["tnc_map_3"],
        dictionary["tnc_map_4"],
        dictionary["tnc_map_5"],
        dictionary["tnc_map_6"],
        dictionary["tnc_map_7"],
        dictionary["tnc_map_8"],
        dictionary["tnc_map_9"],
      ],
      how: [
        dictionary["how_map_1"],
        dictionary["how_map_2"],
        dictionary["how_map_3"],
        dictionary["how_map_4"],
        dictionary["how_map_5"],
        dictionary["how_map_6"],
        dictionary["how_map_7"],
      ],
    },
    shell: {
      tnc: [
        dictionary["tnc_shell_1"],
        dictionary["tnc_shell_2"],
        dictionary["tnc_shell_3"],
        dictionary["tnc_shell_4"],
        dictionary["tnc_shell_5"],
      ],
      how: [dictionary["how_shell_1"], dictionary["how_shell_2"]],
    },
    tokopedia: {
      tnc: [dictionary["tnc_tokopedia_1"], dictionary["tnc_tokopedia_2"]],
      how: [
        parse(
          dictionary["how_tokopedia_1"].replace(
            /{{link}}/g,
            `<a rel="stylesheet" href="https://www.tokopedia.com/gift-card/redeem/" class="cursor-pointer underline text-blue-500">https://www.tokopedia.com/gift-card/redeem/</a>`
          )
        ),
        dictionary["how_tokopedia_1"],
        dictionary["how_tokopedia_2"],
        dictionary["how_tokopedia_3"],
        dictionary["how_tokopedia_4"],
        dictionary["how_tokopedia_5"],
        dictionary["how_tokopedia_6"],
      ],
    },
  };

  const navigate = useNavigate();

  const t = (value) => {
    return dictionary && dictionary[value];
  };

  // Function to check for digital true and return corresponding ids
  function findDigitalIds(products) {
    const digitalIds = [];
    for (const product of products) {
      if (product.digital === true) {
        digitalIds.push(product.order_product_id);
      }
    }
    return digitalIds;
  }

  // Function to filter array based on given ids and specified conditions
  function filterProducts(products, digitalIds) {
    const filteredIds = [];
    for (const product of products) {
      if (digitalIds.includes(product.order_product_id)) {
        if (product.status !== "success") {
          filteredIds.push(product);
        }
      }
    }
    return filteredIds;
  }

  const getOrderRecipient = async () => {
    if (token) {
      const { data: orderRecipient, err } = await controllers.getByToken(token);
      if (err?.message === "Error: Network Error") {
        setStatusCodeZero(true);
      } else if (!err) {
        let recipient = orderRecipient.data;
        let name = recipient.client_name || recipient.customer_name;

        if (step === "digital") {
          setStep(
            recipient.status === "delivered"
              ? "review"
              : recipient.status === "processing"
              ? "thankyou"
              : addressData.length > 0
              ? "address"
              : "new_address"
          );
        }

        // Variable date
        // let claimableUntil = new Date("2024-05-15 00:00:00");
        let claimableUntil = recipient.claimable_until ? new Date(recipient.claimable_until) : null;

        // Current date and time
        let currentDate = new Date();
        setIsExpired(claimableUntil ? currentDate > claimableUntil && recipient.status === "address_requested" : false);

        setOrderRecipient(recipient);
        setTest(recipient.test);
        setNeedRecipientVariation(recipient.need_recipient_variations);
        setRecipientName(name);
      } else {
        navigate("/404");
      }
    }
  };

  const getRecipientProducts = async () => {
    if (token) {
      const { data, err } = await controllers.getRecipientProducts(token);
      // console.log(err.message);
      if (err?.message === "Error: Network Error") {
        setStatusCodeZero(true);
      } else if (!err) {
        setProductsData(data.data);
      }
    }
  };

  const getAddresses = async () => {
    if (token) {
      const { data, err } = await controllers.getAddresses(token);
      if (err?.message === "Error: Network Error") {
        setStatusCodeZero(true);
      } else if (!err) {
        setAddressData(data.data);
      }
    }
  };

  let getPersonalChoosenProducts = async (checkDigital, multi, initialFetch = false) => {
    const { data, err } = await controllers.getPersonalChoosenProducts(token);
    if (err?.message === "Error: Network Error") {
      setStatusCodeZero(true);
    } else if (!err) {
      setChoosensData(data.data);

      let digitalIds = findDigitalIds(data.data);
      let isThereDigitalNeedCheck = filterProducts(data.data, digitalIds).length > 0;

      if (!initialFetch && checkDigital) {
        orderRecipient.order_type !== "branded" && isThereDigitalNeedCheck
          ? setStep("digital")
          : multi
          ? onConfirmRecipientProducts()
          : setStep(
              orderRecipient.status === "processing" ? "thankyou" : addressData.length > 0 ? "address" : "new_address"
            );
      }
    }
  };

  const getPersonalIncludedProducts = async () => {
    if (token) {
      const { data, err } = await controllers.getPersonalIncludedProducts(token);
      if (err?.message === "Error: Network Error") {
        setStatusCodeZero(true);
      } else if (!err) {
        setIncludedata(data.data);
      }
    }
  };

  let updateAddress = async (addressId) => {
    const { err } = await controllers.updateOrderAddress(token, addressId);
    if (err?.message === "Error: Network Error") {
      setStatusCodeZero(true);
    } else if (!err) {
      setStep("thankyou");
    } else {
      mixpanel.track("Error", {
        Message: err.message,
      });
    }
  };

  const onConfirmRecipientProducts = async () => {
    if (orderRecipient.order_request_address) {
      addressData.length > 0 ? setStep("address") : setStep("new_address");
    } else {
      const { err } = await controllers.confirmRecipientProducts(token);
      if (err?.message === "Error: Network Error") {
        setStatusCodeZero(true);
      } else if (!err) {
        setStep("thankyou");
      }
    }
  };

  let onAddressSelected = (address) => {
    updateAddress(address.id);
    getAddresses();
  };

  let onAddAddressSuccess = (address) => {
    updateAddress(address.id);
    getAddresses();
  };

  let onProductsSuccess = () => {
    setNeedRecipientVariation(false);
    setStep(orderRecipient.status === "processing" ? "thankyou" : addressData.length > 0 ? "address" : "new_address");
  };

  const getStepLabel = () => {
    if (step === "start") return "Start";
    else if (step === "address") return "Select Address";
    else if (step === "new_address") return "New Address";
    else if (step === "exchange") return "Exchange Gift";
    else if (step === "digital") return "Digital Form";
    else if (step === "variation") return "Choose Variations";
    else if (step === "thankyou") return "Thank You";
    else if (step === "review") return "Review";
    else if (step === "choose") return "Choose Gift";
    else if (step === "profile") return "Profile";
    else if (step === "delivering") return "Delivering";
    else if (step === "expired") return "Expired";

    return "Other";
  };

  useEffect(() => {
    if (!firstRender) {
      if (orderRecipient) {
        if (orderRecipient.order_products_included) {
          getPersonalIncludedProducts();
        }
        getRecipientProducts();
        getAddresses();
        getPersonalChoosenProducts();
      }
    }
  }, [orderRecipient]); // eslint-disable-line react-hooks/exhaustive-deps

  // get recipient & digitalProvider & dictionary
  useEffect(() => {
    getOrderRecipient();

    if (lang !== "en" && lang !== "id") {
      navigate("/404");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // step update
  useEffect(() => {
    const digitalProductsExist =
      orderRecipient.order_type !== "branded" && filterProducts(choosensData, findDigitalIds(choosensData)).length > 0;

    const updateStep = (value) => {
      setStep(value);
      setFirstRender(true);
      setFirstLoading(false);
    };

    if (!firstRender) {
      if (isExpired) {
        updateStep("expired");
      } else if (orderRecipient.status === "address_requested") {
        if (!orderRecipient.recipient_name) {
          updateStep("profile");
        } else if (!orderRecipient.has_selected_products) {
          updateStep("start");
        } else if (orderRecipient.order_multiple_items) {
          if (digitalProductsExist) {
            updateStep("digital");
          } else if (orderRecipient.order_request_address) {
            updateStep(addressData.length > 0 ? "address" : "new_address");
          } else {
            updateStep("exchange");
          }
        } else if (digitalProductsExist) {
          updateStep("digital");
        } else {
          updateStep(addressData.length > 0 ? "address" : "new_address");
        }
      } else if (orderRecipient.status === "processing") {
        updateStep("thankyou");
      } else if (orderRecipient.status === "delivering") {
        updateStep("delivering");
      } else if (orderRecipient.status === "delivered") {
        updateStep("review");
      }
    }
  }, [choosensData, firstRender]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (step) {
      mixpanel.track("Accept Gift", {
        "Order Number": orderRecipient.order_number,
        Token: token,
        Name: recipientName,
        Test: orderRecipient.test,
        Activate: orderRecipient.activate,
        Step: getStepLabel(),
      });

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [step]); // eslint-disable-line react-hooks/exhaustive-deps

  let isEvent = [
    "n3zMk4jGOp", //Apiary
    "YCmIHINPSm", //Apiary
    "6uEh5eRZXJ", //Apiary
    "OLTbJ7ng7A", //Apiary
    "keacxvddwZ", //Apiary
    "t9PZCuQWhA", //mekari
    "tjb3MFBtAB", //mekari
    "T5tQfcRS0c", //mekari
    "XCEqSny6MG",
  ].includes(orderRecipient.order_number);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        {(orderRecipient.client_name || orderRecipient.customer_name) && (
          <title>
            {`Gift ${lang === "id" ? "Dari" : "From"} ${orderRecipient.client_name || orderRecipient.customer_name}`}
          </title>
        )}
        <meta name="description" content={t("page_desc")}></meta>
        <link rel="preload" fetchpriority="high" as="image" href="/assets/images/gifts/success.png" type="image/webp" />
      </Helmet>

      {statusCodeZero && (
        <div className="bg-white text-black h-screen w-screen flex justify-center items-center">
          <div className="mx-auto max-w-max px-10 md:px-0">
            <main>
              <div className="md:flex items-center mb-3 md:mb-5">
                <p className="text-xl font-bold md:pr-5">500</p>
                <div className="md:border-l md:border-gray-400 md:px-5 py-3">
                  <p className="mt-1">Sorry, something went wrong. Please check your internet and try again.</p>
                </div>
              </div>

              <div
                onClick={() => window.location.reload()}
                className="flex cursor-pointer w-fit md:mx-auto items-center rounded-md  border-transparent bg-secondary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                Try Again
              </div>
            </main>
          </div>
        </div>
      )}

      {!statusCodeZero && firstLoading && (
        <div className="h-screen w-screen p-5">
          <div className="w-full h-full flex items-center justify-center rounded-lg">
            <Spinner color="black" />
          </div>
        </div>
      )}

      {!statusCodeZero && !firstLoading && orderRecipient && step && dictionary && (
        <Suspense>
          {test && <p className="w-full text-center bg-primary text-white p-2 font-bold">{t("test_notice")}</p>}
          <section
            className={`relative w-screen h-full min-h-screen ${
              step === "profile" ? (isEvent ? "bg-[#FFF5E8]" : "bg-blueLight") : "bg-blueLight"
            }  md:bg-white`}
          >
            {step === "loading" && (
              <div className="w-screen h-screen p-10">
                <div className="bg-blueLight w-full h-full rounded-2xl flex justify-center items-center">
                  <Spinner color="black" />
                </div>
              </div>
            )}

            {step === "expired" && <Expired dictionaries={dictionary} />}

            {step === "profile" && !orderRecipient.recipient_name && (
              <React.Fragment>
                {isEvent ? (
                  <ProfileEvent
                    eventName={
                      ["YCmIHINPSm", "6uEh5eRZXJ", "OLTbJ7ng7A", "keacxvddwZ"].includes(orderRecipient.order_number)
                        ? "Apiary"
                        : ["t9PZCuQWhA", "tjb3MFBtAB", "T5tQfcRS0c", "XCEqSny6MG"].includes(orderRecipient.order_number)
                        ? "Mekari"
                        : orderRecipient.order_number === "n3zMk4jGOp"
                        ? "11Space"
                        : "11Space"
                    }
                    dictionaries={dictionary}
                    lang={lang}
                    token={token}
                    setStatusCodeZero={setStatusCodeZero}
                    onSubmit={() => {
                      setStep("start");
                      getOrderRecipient();
                      getAddresses();
                    }}
                  />
                ) : (
                  <ProfileDefault
                    dictionaries={dictionary}
                    token={token}
                    setStatusCodeZero={setStatusCodeZero}
                    onSubmit={() => {
                      setStep("start");
                      getOrderRecipient();
                      getAddresses();
                    }}
                  />
                )}
              </React.Fragment>
            )}

            {step === "start" && (
              <Start
                dictionaries={dictionary}
                orderRecipient={orderRecipient}
                name={recipientName}
                onClick={() =>
                  setStep(
                    orderRecipient.activate && !orderRecipient.recipient_name
                      ? "profile"
                      : !orderRecipient.has_selected_products && orderRecipient.order_type === "personal"
                      ? orderRecipient.products.length > 1 || orderRecipient.products.length <= 0
                        ? "exchange"
                        : "choose"
                      : needRecipientVariation
                      ? "variation"
                      : addressData.length > 0
                      ? "address"
                      : "new_address"
                  )
                }
              />
            )}

            {step === "choose" && (
              <Choose
                dictionaries={dictionary}
                orderRecipient={orderRecipient}
                name={recipientName}
                token={token}
                onExchange={() => setStep("exchange")}
                setStatusCodeZero={setStatusCodeZero}
                onAccept={() => {
                  setStep("loading");
                  getPersonalChoosenProducts(true, false);
                }}
              />
            )}

            {step === "variation" && (
              <Variation
                dictionaries={dictionary}
                orderRecipient={orderRecipient}
                products={productsData}
                setStatusCodeZero={setStatusCodeZero}
                token={token}
                onSuccess={() => {
                  onProductsSuccess();
                  getAddresses();
                }}
              />
            )}

            {step === "exchange" && (
              <Exchange
                dictionaries={dictionary}
                orderRecipient={orderRecipient}
                token={token}
                includedata={includedata}
                setStatusCodeZero={setStatusCodeZero}
                onSuccess={(multi) => {
                  setStep("loading");
                  getAddresses();
                  getPersonalChoosenProducts(true, multi);
                }}
              />
            )}

            {step === "digital" && (
              <Digital
                dictionaries={dictionary}
                getPersonalChoosenProducts={getPersonalChoosenProducts}
                choosenProducts={choosensData}
                filterProducts={filterProducts}
                findDigitalIds={findDigitalIds}
                token={token}
                setStep={setStep}
                getOrderRecipient={getOrderRecipient}
                setStatusCodeZero={setStatusCodeZero}
              />
            )}

            {step === "address" && (
              <Address
                dictionaries={dictionary}
                orderRecipient={orderRecipient}
                addresses={addressData}
                onClick={() => setStep("new_address")}
                token={token}
                onSubmit={onAddressSelected}
                setStatusCodeZero={setStatusCodeZero}
              />
            )}

            {step === "new_address" && (
              <NewAddress
                lang={lang}
                dictionaries={dictionary}
                orderRecipient={orderRecipient}
                token={token}
                onSuccess={onAddAddressSuccess}
                setStatusCodeZero={setStatusCodeZero}
              />
            )}

            {step === "thankyou" && (
              <React.Fragment>
                {orderRecipient.order_type === "branded" ? (
                  <ThankYou
                    dictionaries={dictionary}
                    name={recipientName}
                    token={token}
                    addresses={addressData}
                    orderRecipient={orderRecipient}
                    setStatusCodeZero={setStatusCodeZero}
                    onChangeAddressClick={() => {
                      getAddresses();
                      addressData.length > 0 ? setStep("address") : setStep("new_address");
                    }}
                    onChangeVariationClick={() => {
                      setStep("variation");
                    }}
                  />
                ) : (
                  <React.Fragment>
                    {orderRecipient.order_type !== "branded" && findDigitalIds(choosensData).length === 0 ? (
                      <ThankYou
                        dictionaries={dictionary}
                        name={recipientName}
                        token={token}
                        addresses={addressData}
                        orderRecipient={orderRecipient}
                        setStatusCodeZero={setStatusCodeZero}
                        onChangeAddressClick={() => {
                          getAddresses();
                          addressData.length > 0 ? setStep("address") : setStep("new_address");
                        }}
                        onChangeVariationClick={() => {
                          setStep("variation");
                        }}
                      />
                    ) : (
                      <ThankYouDigital
                        provider={digitalProvider}
                        dictionaries={dictionary}
                        token={token}
                        step={step}
                        choosenProducts={choosensData}
                        addresses={addressData}
                        orderRecipient={orderRecipient}
                        setStatusCodeZero={setStatusCodeZero}
                        onChangeAddressClick={() => {
                          getAddresses();
                          addressData.length > 0 ? setStep("address") : setStep("new_address");
                        }}
                      />
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            {step === "delivering" && (
              <React.Fragment>
                {orderRecipient.order_type === "branded" ? (
                  <Delivering
                    setStatusCodeZero={setStatusCodeZero}
                    dictionaries={dictionary}
                    orderRecipient={orderRecipient}
                    token={token}
                  />
                ) : (
                  <React.Fragment>
                    {findDigitalIds(choosensData).length === 0 ? (
                      <Delivering
                        setStatusCodeZero={setStatusCodeZero}
                        dictionaries={dictionary}
                        orderRecipient={orderRecipient}
                        token={token}
                      />
                    ) : (
                      <ThankYouDigital
                        provider={digitalProvider}
                        dictionaries={dictionary}
                        step={step}
                        token={token}
                        choosenProducts={choosensData}
                        addresses={addressData}
                        orderRecipient={orderRecipient}
                        setStatusCodeZero={setStatusCodeZero}
                        onChangeAddressClick={() => {
                          getAddresses();
                          addressData.length > 0 ? setStep("address") : setStep("new_address");
                        }}
                      />
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            {step === "review" && (
              <ReviewDigital
                provider={digitalProvider}
                dictionaries={dictionary}
                token={token}
                choosenProducts={choosensData}
                orderRecipient={orderRecipient}
                setStatusCodeZero={setStatusCodeZero}
              />
            )}
          </section>
        </Suspense>
      )}
    </React.Fragment>
  );
}

export default GiftLayout;
