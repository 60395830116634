import React from "react";
import { createRoot } from "react-dom/client";

import "./styles/index.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import NotFound from "./components/NotFound";
import Token from "./layout/Token";
import GiftLayout from "./layout/GiftLayout";
import mixpanel from "./lib/mixpanel";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const container = document.getElementById("root");
const root = createRoot(container);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN || "https://b61fc86d27d34b3986587b684c5fb786@o1194500.ingest.sentry.io/6317348",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
mixpanel.init();

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/:lang/:token" exact element={<GiftLayout />} />
      <Route path="/:token" exact element={<Token />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  </BrowserRouter>
);
