import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import controllers from "../lib/api";
import Spinner from "../components/Spinner";

function Token() {
  let { token } = useParams();
  const navigate = useNavigate();

  const loadRecipient = async () => {
    if (token) {
      const { data: orderRecipient, err } = await controllers.getByToken(token);
      if (!err) {
        let recipientLang = orderRecipient.data.lang;
        let lang = recipientLang === "en" || recipientLang === "id" ? recipientLang : "id";
        navigate(`/${lang}/${token}`);
      } else {
        navigate("/404");
      }
    }
  };

  useEffect(() => {
    loadRecipient();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="h-screen w-screen p-5">
      <div className="w-full h-full flex items-center justify-center rounded-lg">
        <Spinner color="black" />
      </div>
    </div>
  );
}

export default Token;
