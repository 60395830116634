import React from "react";
import { Helmet } from "react-helmet";

export default function NotFound() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Gifts Not Found</title>
      </Helmet>

      <div className="bg-white text-black h-screen w-screen flex justify-center items-center">
        <div className="mx-auto max-w-max px-10 md:px-0">
          <main>
            <div className="md:flex items-center mb-3 md:mb-5">
              <p className="text-xl font-bold md:pr-5">404</p>
              <div className="md:border-l md:border-gray-400 md:px-5 py-3">
                <p className="mt-1">This page could not be found.</p>
              </div>
            </div>
          </main>
        </div>
      </div>
    </React.Fragment>
  );
}
