import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

async function get(url, params) {
  try {
    params = params || {};
    const res = await api.get(url, {
      params: params,
    });

    return {
      err: null,
      data: res.data,
      res: res,
    };
  } catch (error) {
    const res = error.response;
    var message = error?.message || "Error: Network Error";

    return {
      err: {
        message: message,
      },
      data: res ? res.data : null,
      res: res,
    };
  }
}

const req = async (method, url, data, options) => {
  try {
    const res = await api[method](url, data, options);
    return {
      err: null,
      data: res.data,
      res: res,
    };
  } catch (error) {
    const res = error.response;
    const data = res ? res.data || null : null;
    const errors = data !== null && typeof data === "object" ? data.errors || null : null;

    var errMessage = null;
    if (res && res.status >= 400 && res.status < 500) {
      if (errors) {
        for (const prop in errors) {
          if (errors.hasOwnProperty(prop)) {
            for (let i = 0; i < prop.length; i++) {
              errMessage = errors[prop];
              break;
            }
          }
          if (errMessage) break;
        }
      } else {
        for (const prop in data) {
          if (data.hasOwnProperty(prop)) {
            for (let i = 0; i < prop.length; i++) {
              errMessage = data[prop][0];
              break;
            }
          }
          if (errMessage) break;
        }
      }
    }
    errMessage = errMessage ? errMessage : error?.message || "Error: Network Error";

    return {
      err: {
        message: errMessage,
      },
      data: data,
      res: res ? res : null,
    };
  }
};
async function post(url, data, options) {
  return await req("post", url, data, options);
}
async function put(url, data, options) {
  return await req("put", url, data, options);
}
async function patch(url, data, options) {
  return await req("patch", url, data, options);
}
async function del(url, options) {
  return await req("delete", url, {}, options);
}

const controllers = {
  getByToken: (token) => {
    return get(`/orders/order-recipients/${token}`);
  },
  getRecipientProducts: (token) => {
    return get(`/orders/order-recipients/${token}/products`);
  },
  updateRecipientProducts: (token, params) => {
    return post(`/orders/order-recipients/${token}/products`, {
      products: params,
    });
  },
  deleteRecipientProducts: (token, id) => {
    return del(`/orders/order-recipients/${token}/personal-products/${id}`);
  },
  confirmRecipientProducts: (token) => {
    return post(`/orders/order-recipients/${token}/process`);
  },
  getCategories: (token) => {
    return get(`/orders/order-recipients/${token}/categories`);
  },
  getSubcategories: (token, id) => {
    return get(`/orders/order-recipients/${token}/categories/${id}/subcategories`);
  },
  getPersonalProducts: (token, params) => {
    return get(`/orders/order-recipients/${token}/personal-products`, params);
  },
  getPersonalChoosenProducts: (token) => {
    return get(`/orders/order-recipients/${token}/chosen-products`);
  },
  getPersonalIncludedProducts: (token) => {
    return get(`/orders/order-recipients/${token}/included-products`);
  },
  getPersonalProduct: (token, id) => {
    return get(`/orders/order-recipients/${token}/personal-products/${id}`);
  },
  addPersonalProduct: (token, params) => {
    return post(`/orders/order-recipients/${token}/personal-products`, {
      products: [params],
    });
  },
  addDigitalProductNumber: (token, productId, params) => {
    return post(`/orders/order-recipients/${token}/digital-products/${productId}`, params);
    // “customer_id”: “08xxxx”
  },
  updateOrderAddress: (token, id) => {
    return put(`/orders/order-recipients/${token}/address`, {
      address_id: id,
    });
  },
  getChoosenAddress: (token) => {
    return get(`/orders/order-recipients/${token}/address`);
  },
  getChoosenVariation: (token) => {
    return get(`/orders/order-recipients/${token}/chosen-variations`);
  },
  getCourierServices: (token, courier) => {
    return get(`/orders/order-recipients/${token}/courier-services/${courier}`);
  },
  updateCourier: (token, courier, service, cost) => {
    return put(`/orders/order-recipients/${token}/courier`, {
      courier: courier,
      service: service,
      cost: cost,
    });
  },
  getCourierHistories: (token) => {
    return get(`/orders/order-recipients/${token}/courier-histories`);
  },
  updateProfile: (token, params) => {
    return put(`/orders/order-recipients/${token}/profile`, params);
  },
  updateReview: (token, params) => {
    var formData = new FormData();
    formData.append("happiness", params.happiness);
    formData.append("matching", params.matching);
    formData.append("quality", params.quality);
    formData.append("review", params.review);
    if (params.image) {
      formData.append("image", params.image);
    }

    return post(`/orders/order-recipients/${token}/review`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  acceptProducts: (token) => {
    return post(`/orders/order-recipients/${token}/accept-products`);
  },
  getProvinces: () => {
    return get(`/recipients/regions?type=province`);
  },
  getCities: (provinceId) => {
    return get(`/recipients/regions?type=city&province_id=${provinceId}`);
  },
  getSubdistricts: (provinceId, cityId) => {
    return get(`/recipients/regions?type=subdistrict&province_id=${provinceId}&city_id=${cityId}`);
  },
  getAddresses: (token) => {
    return get(`/recipients/order-recipients/${token}/addresses`);
  },
  createAddress: (token, params) => {
    return post(`/recipients/order-recipients/${token}/addresses`, params);
  },
  deleteAddress: (token, id) => {
    return del(`/recipients/order-recipients/${token}/addresses/${id}`);
  },
  createOrder: (params) => {
    return post(`/orders/orders`, params);
  },
  getOrder: (orderNumber) => {
    return get(`/orders/orders/${orderNumber}`);
  },
  createPaymentLink: (orderNumber) => {
    return post(`/orders/orders/${orderNumber}/payment-links`);
  },
  createTestOrder: (params) => {
    return post(`/orders/orders/tests`, params);
  },
  confirmGuide: (params) => {
    return post(`/products/corporate-gift-guide-2023`, params);
  },

  // PRODUCTS
  // params => page, limit, name, category_id, category_type, tag_id, price, personal, sort
  getProducts: (params) => {
    let _params = {
      ...{
        page: 1,
        limit: 20,
      },
      ...params,
    };

    return get(`/products/products`, _params);
  },
  getProductCategories: () => {
    return get(`/products/categories`);
  },
  getProductCategory: (id) => {
    return get(`/products/categories/${id}`);
  },
  getProductSubcategories: (id) => {
    return get(`/products/categories/${id}/subcategories`);
  },
  getProductTag: (id) => {
    return get(`/products/tags/${id}`);
  },
  getProduct: (id) => {
    return get(`/products/products/${id}`);
  },

  // REGISTER PARTNERSHIP
  createPartnership: (params) => {
    return post(`/clients/partnerships`, params);
  },

  // FOR PACKAGES & BRANDS
  getTags: (params) => {
    let _params = {
      ...{
        page: 1,
        limit: -1,
        type: "package",
      },
      ...params,
    };

    return get(`/products/tags`, _params);
  },
  getPackages: (params) => {
    let _params = {
      ...{
        page: 1,
        limit: -1,
      },
      ...params,
    };
    // budget=10000&category=tag_id&page=1
    return get(`/products/packages`, _params);
  },
  getPackage: (id) => {
    return get(`/products/packages/${id}`);
  },
  getBrands: (params) => {
    let _params = {
      ...{
        page: 1,
        limit: -1,
      },
      ...params,
    };
    return get(`/products/brands`, _params);
  },

  // FOR CREATE HAMPERS
  createCartToken: () => {
    return post(`/orders/carts`);
  },
  getCart: (token) => {
    return get(`/orders/carts/${token}`);
  },
  updateCart: (token, params) => {
    return patch(`/orders/carts/${token}`, params);
  },
  addCartProduct: (token, params) => {
    return post(`/orders/carts/${token}/products`, params);
  },
  deleteCartProduct: (token, id) => {
    return del(`/orders/carts/${token}/products/${id}`);
  },
};

export default controllers;
