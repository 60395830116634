export default function Expired({ dictionaries }) {
  const t = (value) => {
    return dictionaries[value];
  };

  return (
    <div className="md:bg-white w-screen h-screen md:p-10">
      <div className="h-full w-full bg-blueLight rounded-lg flex flex-col items-center justify-center gap-10 overflow-hidden">
        {/* image  */}
        <img
          src={"/assets/images/gifts/expired-icon-new.png"}
          alt="expired-icon"
          className="w-full min-w-[15rem] max-w-[18rem] max-h-[60vh]"
        />

        <div className="text-center">
          <h1 className="text-2xl md:text-4xl font-bold mb-4 md:mb-6">{t("expired_title")}</h1>

          <p className="text-lg md:text-xl text-gray-600">{t("expired_message")}</p>
        </div>
      </div>
    </div>
  );
}
